import React from 'react';
import {IconCall, IconMessage, IconPin, IconOpenLink} from '../../components/icons/Icons'; 

const Sidebar = () => {
    return (
        <div className="contact-left-column section-padding">
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconMessage />
                        <h2 className="oriya-capitalise-small">Chat with us</h2>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>Our friendly team is here to help.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <a href="mailto:support@helloaccessible.com" className="avenir-lowercase-tiny open-link-new-tab">
                            <b>support@helloaccessible.com</b>
                        </a>
                    </div>
                </div>
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconCall />
                        <h2 className="oriya-capitalise-small">Call us</h2>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>We're always happy to connect.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <a href="https://calendly.com/helloaccessible" className="avenir-lowercase-tiny open-link-new-tab" target="_blank" aria-label="Link opens in a new window">
                            <b>Book a call</b> <IconOpenLink />
                        </a>
                    </div>
                </div>
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconPin />
                        <h2 className="oriya-capitalise-small">Visit us</h2>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>Come say hello at our office.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p className="avenir-lowercase-tiny">
                            <b>1575 W Georgia St, Vancouver, BC</b>
                        </p>
                    </div>
                </div>
            </div>
    );
}

export default Sidebar;