import React, {Fragment} from 'react';
import {IconOpenLink} from './icons/Icons';

const CTAButton = () => {
  return (
    <button
        onClick={() => window.open("https://calendly.com/helloaccessible/discovery", "_blank")}
        className="button open-link-new-tab"
        aria-label="Link opens in a new tab"
    >
        Book your free discovery call <IconOpenLink />
    </button>
  );
}

export default CTAButton;
