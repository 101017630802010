import React, {Fragment, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import { 
  IconAudit, IconTrainings, IconVpat, IconRemediation, IconCustom, IconPlan,
  IconArrow
} from '../../components/icons/Icons';
import BookingProcess from '../../components/BookingProcess';

const ServicesTabButton = ({ icon, tabTitle, isSelected }) => (
  <Fragment>
    <div className="services-tab-button">
      {icon}
      {tabTitle}
    </div>
    {isSelected ? <IconArrow /> : <Fragment />}
  </Fragment>
);

const ServicesTabContent = ({ title, expectations, pitch, imgSrc, alt, process, buttonLink, buttonTitle }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => navigate('/contact');

  return (
    <div className="tab-content">
      <h3 className="oriya-capitalise-small">{title}</h3>
      <div className="services-tabs-content">
        <div>
          <h3 className="avenir-capitalise-small">What can I expect?</h3>
          <p>{expectations}</p>
          <h3 className="avenir-capitalise-small">Why does my business need it?</h3>
          <p>{pitch}</p>
        </div>
        <img className="tab-images" src={imgSrc} alt={alt} />
      </div>
      <br />
      {process}
      <button onClick={handleButtonClick} className="button">{buttonTitle}</button>
    </div>
  )
};

const HomeServices = () => {
  const services = [
    {
      id: 0,
      icon: <IconAudit />,
      tabTitle: "Audit",
      title: "Digital Accessibility Audit",
      expectations: "The audit is a thorough manual review of your website for accessibility gaps. It produces a report of discovered issues and code remediation suggestions. If you have a development team, they could use it to fix the issues and upskill on accessibility criterions. Otherwise, we also offer remediation services.",
      pitch: "Audit reports give you a clear idea on where and how to start making your website accessible for your customers. From there, we can map out your roadmap to prioritise these issues accordingly.",
      imgSrc: "../../../assets/report.png",
      alt: "Example of an audit report by Hello Accessible",
      process: <BookingProcess />,
      buttonLink: "/contact",
      buttonTitle: "Request an audit",
    },
    {
      id: 1,
      icon: <IconRemediation />,
      tabTitle: "Remediation",
      title: "Digital Accessibility Remediation",
      expectations: "Our remediation services focus on execution. We deep dive into your code, designs or software development life cycle. Rest assured that no matter the technical challenge, we are focused on the outcome of full accessibility compliance.",
      pitch: "Remediation services address the specific accessibility issues uncovered in your audit report, translating technical findings into actionable solutions. Our experts work seamlessly with your developers or handle the fixes directly, so you can focus on your business while we ensure your digital platforms meet accessibility standards.",
      imgSrc: "",
      alt: "",
      process: "",
      buttonLink: "/contact",
      buttonTitle: "Request remediation",
    },
    {
      id: 2,
      icon: <IconTrainings />,
      tabTitle: "Training",
      title: "Digital Accessibility Training",
      expectations: "Our training sessions are tailored to your team’s needs, whether technical or non-technical. Developers will learn actionable skills to create accessible code and resolve accessibility issues, while non-technical teams will gain a deeper understanding of the importance of accessibility, how it impacts your business, and ways to champion inclusivity. Expect hands-on examples, practical guidance, and a supportive environment that empowers your team to prioritize accessibility confidently.",
      pitch: "Accessibility is not a one-time fix — it is an ongoing commitment. By equipping your team with the knowledge and skills to implement and maintain accessibility, you reduce long-term costs, improve user experiences, and ensure compliance with legal standards. Training ensures accessibility becomes a seamless part of your workflow, helping your business build more inclusive and innovative digital experiences.",
      imgSrc: "",
      alt: "",
      process: "",
      buttonLink: "/contact",
      buttonTitle: "Request trainings",
    },
    {
      id: 3,
      icon: <IconPlan />,
      tabTitle: "Roadmap Planning",
      title: "Digital Accessibility Roadmap Planning",
      expectations: "Our roadmap planning service delivers a tailored accessibility strategy, including a detailed action plan, prioritized recommendations, and timelines to address your specific needs. You will receive clear deliverables such as measurable goals, resource requirements, and tools to track your progress effectively.",
      pitch: "An accessibility roadmap equips your business with a step-by-step plan to meet accessibility standards and improve inclusivity. By providing clear priorities, actionable steps, and ongoing guidance, we help you integrate accessibility into your operations without guesswork.",
      imgSrc: "",
      alt: "",
      process: "",
      buttonLink: "/contact",
      buttonTitle: "Request roadmap planning",
    },
    {
      id: 4,
      icon: <IconVpat />,
      tabTitle: "VPAT",
      title: "VPAT Creation",
      expectations: "Our VPAT (Voluntary Product Accessibility Template) service provides a professionally prepared document that outlines how your product meets accessibility standards. You will receive a detailed, accurate VPAT report tailored to your product, highlighting compliance and areas for improvement to meet regulations like WCAG, Section 508, and EN 301 549.",
      pitch: "A VPAT is essential for businesses selling to government agencies or organizations requiring proof of accessibility compliance. By showcasing your commitment to inclusivity and transparency, a well-crafted VPAT helps you stay competitive, build trust, and open doors to new markets.",
      imgSrc: "",
      alt: "",
      process: "",
      buttonLink: "/contact",
      buttonTitle: "Request VPAT creation",
    },
    {
      id: 5,
      icon: <IconCustom />,
      tabTitle: "Custom",
      title: "Custom Accessibility Solutions",
      expectations: "Our custom accessibility service starts with a discovery process to understand your business, audience, and goals. Through collaborative calls and consultations, we will help you identify your specific accessibility needs, set clear expectations, and develop a plan that is tailored to your unique requirements.",
      pitch: "No two businesses are the same, and cookie-cutter solutions often fall short. Custom accessibility services ensure that your approach is practical, effective, and fully aligned with your workflows, helping you achieve compliance and inclusivity without compromising your unique business goals.",
      imgSrc: "",
      alt: "",
      process: "",
      buttonLink: "/contact",
      buttonTitle: "Request for custom solutions",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(0); 
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const {buttonProps, itemProps} = useDropdownMenu(services.length);

  const handleDropdownClick = (index) => {
    setSelectedTab(index); 
    setDropdownOpen(false);
  };

    return (
      <section className="services-section section-padding" id="services" role="contentinfo">
        <div className="section-heading">
          <p className="avenir-uppercase-tiny">Your one-stop shop for inclusive experiences</p>
          <br />
          <h2 className="oriya-uppercase-medium">Our Digital Accessibility Solutions</h2>
          <br />
        </div>
        <button {...buttonProps} onClick={() => setDropdownOpen(!isDropdownOpen)} className="dropdown-button services-tab-button">
          <div className="services-tab-button">
            {services[selectedTab].icon}
            {services[selectedTab].tabTitle}
          </div>
          <IconArrow />
        </button>
        <div className={isDropdownOpen ? "visible dropdown-menu" : ""} role="menu">
          {services.map((service, index) => (
            <a
              key={service.id}
              {...itemProps[index]}
              onClick={(e) => {
                e.preventDefault();
                handleDropdownClick(index);
              }}
            >
              <div className="services-tab-button">
                {service.icon}
                {service.tabTitle}
              </div>
              {services[selectedTab].tabTitle === service.tabTitle ? <IconArrow /> : ""}
            </a>
          ))}
        </div>

        <Tabs 
          className="react-tabs section-padding-content"
          selectedIndex={selectedTab}
          onSelect={(index) => setSelectedTab(index)}
      >
          <TabList>
            {services.map((service, index) => (
              <Tab key={service.id}>
                <ServicesTabButton icon={service.icon} tabTitle={service.tabTitle} isSelected={selectedTab === index} />
              </Tab>
            ))}
          </TabList>
          {services.map((service) => (
            <TabPanel key={service.id}>
              <ServicesTabContent
                title={service.title}
                expectations={service.expectations}
                pitch={service.pitch}
                imgSrc={service.imgSrc}
                alt={service.alt}
                process={service.process}
                buttonLink="/contact"
                buttonTitle={service.buttonTitle}
              />
            </TabPanel>
          ))}
        </Tabs>
      </section>
    );
}

export default HomeServices;
