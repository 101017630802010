import React from 'react';
import {ReactTicker} from '@guna81/react-ticker';

const data = [
    {
      id: 1,
      value:
        "In the US alone, people with disabilities control $1.9 trillion in disposable income."
    },
    {
      id: 2,
      value:
        "90% of websites are inaccessible to people with disabilities who use assistive technologies."
    },
    {
      id: 3,
      value:
        "More than 6.2 million Canadians have disabilities, representing a substantial market segment."
    },
  ];
  
  const HomeTicker = () => {
    return (
      <ReactTicker
        data={data.map((item) => item.value)}
        speed={50}
        keyName="_id"
        tickerClassName="spotlight-purple"
      />
    );
  }

  export default HomeTicker;