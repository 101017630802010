import React from 'react';
import CTAButton from '../../components/CTAButton';

const HomeHeader = () => {
  return (
    <header id="content" role="main">
      <div className="header-text-container">
        <h1 className="oriya-uppercase-big">
          Can <i>All</i> Your Customers Access Your Online Business?
        </h1>
        <p>
          Hello Accessible is a digital accessibility consulting firm in Vancouver, BC. We empower businesses to grow their reach with expert audits, remediation services, and targeted training. Just as we value all beings, accessibility ensures your platform serves every online customer. Find and fix what is not working to grow your reach and unlock new opportunities.
        </p>
        <CTAButton />
      </div>
      <div className="header-video-container">
        <video loop autoPlay muted playsInline aria-hidden="true" tab-index="-1">
          <source src="../../assets/stock-vid-2-edited.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </header>
  );
}

export default HomeHeader;