import React, {Fragment} from 'react';

const Team = props => {
    return (
        <Fragment>
            <h2 className="oriya-uppercase-medium">Team</h2>
                <div className="about-team">
                    <div>
                        <div className="about-team-photos">
                            <img src="../../../assets/founder.jpeg" alt="Photo of Hello Accessibility's founder" />
                        </div>
                        <div className="about-team-name">
                            <h2 className="avenir-capitalise-small">Patricia Goh</h2>
                            <h3 className="avenir-lowercase-tiny"><i><b>(she/her)</b></i></h3>
                        </div>
                        <p className="avenir-uppercase-tiny">Founder</p>
                        <br />
                        <p>
                            With over 8 years of global experience in software engineering and leadership, I specialize in building high-performing teams and driving strategic initiatives. As the founder of Hello Accessible, I am dedicated to helping organisations create accessible digital experiences. My mission is to bridge the gap between technology and inclusivity, ensuring that all individuals, regardless of ability, have a way to engage with the digital world. For me, true accessibility is about not being an afterthought - it is baked in from the start, so we can build a future where technology empowers everyone.
                        </p>
                    </div>
                    <div>
                        <div className="about-team-photos">
                            <img src="../../../assets/head-of-purrfessional-wellness.jpeg" alt="Photo of Hello Accessibility's emotional support cat" />
                        </div>
                        <div className="about-team-name">
                            <h2 className="avenir-capitalise-small">Kafka</h2>
                            <h3 className="avenir-lowercase-tiny"><i><b>(he/him)</b></i></h3>
                        </div>
                        <p className="avenir-uppercase-tiny">Chief Purr-fessional Wellness Officer</p>
                        <br />
                        <p>
                            As Hello Accessible's fluffy morale booster and resident cuddle connoisseur, I bring a natural curiosity and keen eye for detail. Through the years hunting down real bugs, I have mastered the art of spotting "invisible" details, just like our accessibility experts! I take my role very seriously, ensuring my team takes stretch breaks and stay paw-sitive. Known for my attentiveness, I also occasionally "test" my team's work by walking across their keyboards. My presence reminds my team that even the smallest gestures can make a big impact, just like the accessibility work we do that make our digital world more inclusive.
                        </p>
                    </div>
                    <div>
                        <div className="about-team-photos" />
                        <div className="about-team-name">
                            <h2 className="avenir-capitalise-small">This could be you</h2>
                        </div>
                        <p className="avenir-uppercase-tiny">Your Title</p>
                        <br />
                        <p>
                            We're always looking for talented and passionate professionals to join us in our mission. Please reach out to us with your resumé and let's start the conversation!
                        </p>
                    </div>
                </div>
        </Fragment>
    )
}

export default Team;