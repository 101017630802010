import React, {Fragment} from 'react';
import { Helmet } from "react-helmet-async";
import GeneralAbout from './about/GeneralAbout';
import Team from './about/Team';
 
const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>About Hello Accessible - Digital Accessibility Consulting Firm | Vancouver</title>
      </Helmet>
      <section className="about-page section-padding"  role="contentinfo" id="content">
        <GeneralAbout />
        <Team />
      </section>
    </Fragment>
  );
}

export default About;
